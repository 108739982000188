<template>
  <div class="app">
    <el-form
      ref="searchform"
      key="searchform"
      :model="searchform"
      :inline="true"
    >
      <el-form-item prop="type">
        <el-radio-group
          v-model="searchform.type"
          size="small"
          @change="tabChange"
        >
          <el-radio-button label="1">测试中</el-radio-button>
          <el-radio-button label="2">测试完成</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="date">
        <el-date-picker
          v-model="searchform.date"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          size="small"
            style="width:140px"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList" size="mini"
          >查询</el-button
        >
        <el-button type="primary" @click="getList" size="mini"
          >刷新</el-button
        >
      </el-form-item>
      <el-form-item prop="intervalTime">
        <el-input
          v-model="searchform.intervalTime"
          placeholder="请输入定时刷新时间"
          size="small"
          @blur="getList"
          style="width:40px"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" style="width: 100%;" :header-cell-style="getRowClass">
        <el-table-column
          prop="imei"
          label="IMEI"
          :key="searchform.type + Math.random()"
        >
        </el-table-column>
        <el-table-column
          prop="lose"
          label="-5%"
          v-if="searchform.type == 1"
          :key="searchform.type + Math.random()+'lose'"
        >
          <template slot-scope="scope">
            {{(scope.row.speed*100*0.95/100).toFixed(0)}}
          </template>
        </el-table-column>
        <el-table-column
          prop="speed"
          label="最新转速"
          v-if="searchform.type == 1"
          :key="searchform.type + Math.random()"
        >
        </el-table-column>
        <el-table-column
          prop="normal"
          label="+5%"
          v-if="searchform.type == 1"
          :key="searchform.type + Math.random()+'normal'"
        >
        <template slot-scope="scope">
          {{(scope.row.speed*100*1.05/100).toFixed(0)}}
        </template>
        </el-table-column>

        <el-table-column
          prop="temperature1"
          label="温度1"
          v-if="searchform.type == 1"
          :key="searchform.type + Math.random()"
        >
        </el-table-column>
        <el-table-column
          prop="temperature2"
          label="温度2"
          v-if="searchform.type == 1"
          :key="searchform.type + Math.random()"
        >
        </el-table-column>
        <el-table-column
          prop="signal"
          label="信号强度"
          v-if="searchform.type == 1"
          :key="searchform.type + Math.random()"
        >
        </el-table-column>
        <el-table-column
          prop="power"
          label="电量"
          v-if="searchform.type == 1"
          :key="searchform.type + Math.random()"
        >
        </el-table-column>
        <el-table-column
          prop="testTime"
          label="时间"
          :key="searchform.type + Math.random()"
        >
        </el-table-column>
        <el-table-column
          prop="testStatus"
          label="结果"
          v-if="searchform.type == 2"
          :key="searchform.type + Math.random()"
        >
        <template slot-scope="scope">
          <span v-if="scope.row.testStatus==1">测试合格</span>
          <span v-if="scope.row.testStatus==2">测试不合格</span>
        </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              v-if="searchform.type == 1"
              @click="handleUpdate(scope.row, 1)"
              >合格
            </el-button>
            <el-button
              size="mini"
              type="text"
              v-if="searchform.type == 1"
              @click="handleUpdate(scope.row, 2)"
              >不合格
            </el-button>
            <el-button
              size="mini"
              type="text"
              v-if="searchform.type == 2"
              @click="handleUpdate(scope.row, 0)"
              >重测
            </el-button>
          </template>
        </el-table-column>
    </el-table>
  </div>
</template>

<script>
import util from "../pages/publics/util";
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    const headers = [
        { title: 'IMEI', isShow: true, prop: 'imei', width: 100 },
        { title: '-5%', isShow: true, prop: 'name', width: 150 },
        { title: '最新转速', isShow: true, prop: 'sex', width: 100 },
        { title: '+5%', isShow: true, prop: 'workNumber', width: 150 },
        { title: '时间', isShow: true, prop: 'cardNo', width: 100 },
    ]
    const header = [
        { title: 'IMEI', isShow: true, prop: 'imageUrl', width: 100 },
        { title: '温度1', isShow: true, prop: 'name', width: 150 },
        { title: '温度2', isShow: true, prop: 'name', width: 150 },
        { title: '信号强度', isShow: true, prop: 'name', width: 150 },
        { title: '电量', isShow: true, prop: 'name', width: 150 },
        { title: '时间', isShow: true, prop: 'name', width: 150 },
        { title: '结果', isShow: true, prop: 'sex', width: 100 },
    ]
    return {
      loading: true,
      searchform: {
        type: 1, //1=测试中，2=测试完成
        date: "",
        intervalTime: 3,
      },
      today: "",
      columnHeaders: [...headers],
      columnHeader: [...header],
      tableData:[],
      searchvideoTimer:null,
    }
  },
  created() {
    this.today = util.timeFormat2(new Date());
    this.searchform.date = util.timeFormat2(new Date());
    this.tabChange()
  },
  watch: {

  },
  methods: {
    getRowClass() {
        return 'background:#EFEFEF'
    },
    tabChange(){
      this.tableData =[];
      this.getList();
       if (!this.searchvideoTimer) {
         this.repeat()
      }
    },
    getList() {
      this.$http({
        method: 'get',
        // url: 'http://192.168.1.14:8801/factory/list', // 
        url: 'http://app.xunjiantuogun.com/api/factory/list',
        params: this.searchform,
      }).then((data) => {
        if(data.data.code == '10000'){
          this.tableData = data.data.resultData
          if(this.loading){
            this.loading = false
          }
        }
        // console.log(data.data.rows);
      })
    },
    //登录
    handleUpdate(row, status) {
      // 状态 1=合格，2=不合格，0=重测(未测试)
      console.log(row, status);
      this.$http({
        method: 'POST',
        // url: 'http://192.168.1.14:8801/factory/changeStatus',
        url: 'http://app.xunjiantuogun.com/api/factory/changeStatus',
        data: {
          status: status,
          imei: row.imei,
        },
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
      }).then(() => {
        this.getList()
      })
    },
    // 定时器
    repeat(){
      this.searchvideoTimer = setInterval(() => {
        this.getList();
      }, this.searchform.intervalTime * 1050);
    },
  },
  beforeDestory(){
    this.searchvideoTimer = null
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.app {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 20px 10px;
    ::v-deep .el-table__header-wrapper{
        background-color: black;
      .el-table__header{
          background-color: black;
        .has-gutter{
          background-color: black;
          .is-leaf .el-table__cell{
            background-color: black;
            .cell{
              background-color: black;
            }
          }
        }
      }
    }
}


</style>