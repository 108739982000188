import Vue from 'vue'
import App from './App.vue'
import Cookies from 'js-cookie'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)

Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.$cookie = Cookies;


new Vue({
    render: h => h(App),
}).$mount('#app')